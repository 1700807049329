import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Header from "./Header.js";

class Splash extends Component {



    render() {


        return (
            <div>
                <Header />

                <div className="dashboard_body main_body_style" id="sale_order_page">
                    <div className="pos_main">
                        <div className="pos_black_box" id="new_selection">
                            <div className="main_body">
                                <div className="login_screen " id="uder_eng_language">
                                    <div className="user_login  process_complete">


                                        <div className="form_box Request_door splash_getstart" id="request_door">
                                            {/* <NavLink to="/dashboard" ><button
                                                className="button-loadings" type="button"
                                            >
                                                Get Started
                                            </button></NavLink> */}
                                            <NavLink to="/dashboard" ><span className="recording"></span></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}
export default Splash;
