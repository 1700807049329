import React, { Component } from 'react';
import { BrowserRouter, Route, HashRouter, Redirect } from 'react-router-dom';
import Login from './components/Login';
import TimeSelection from './components/TimeSelection';
import NewSelection from './components/NewSelection';
import ProductDetail from './components/ProductDetail';
import ProductsDetail from './components/ProductsDetail';
import ContactUs from './components/ContactUs';
import Orderlisting from './components/Orderlisting';
import ForgetPassword from './components/ForgetPassword';
import ChangePassword from './components/ChangePassword';
import ResetPassword from './components/ResetPassword';
import InvoiceList from './components/InvoiceList';
import Dashboard from './components/Dashboard';
import Pos from './components/Pos';
import PosInvoice from './components/PosInvoice';
import SaleOrder from './components/SaleOrder';
import PosListing from './components/PosListing';
import Cashbook from './components/Cashbook';
import ThermalInvoice from './components/ThermalInvoice';
import Checkout from './components/Checkout';
import Expence from './components/Expence';
import CashReceive from './components/CashReceive';
import ReturnInvoice from './components/ReturnInvoice';
import Splash from './components/Splash';
class App extends Component {

	constructor(props) {
		super(props);
		this.state = {

		}
	}

	render() {
		return (
			<div>
				<HashRouter>

					<Route exact path="/" component={Login} />
					<Route path={`${localStorage.getItem('orderId') ? '/item-selection/:orderId' : '/item-selection'}`} component={TimeSelection} />
					<Route path={`${localStorage.getItem('orderId') ? '/new-selection/:orderId' : '/new-selection'}`} component={NewSelection} />
					<Route path="/invoice-list" component={InvoiceList} />
					<Route path={`${localStorage.getItem('orderId') ? '/product-detail/:orderId' : '/product-detail'}`} component={ProductDetail} />
					<Route path="/contact-us" component={ContactUs} />
					<Route path="/products-detail" component={ProductsDetail} />
					<Route path="/order-listing" component={Orderlisting} />
					<Route path="/forget-password" component={ForgetPassword} />
					<Route path="/change-password" component={ChangePassword} />
					<Route path="/reset-password" component={ResetPassword} />
					<Route path="/dashboard" component={Dashboard} />
					<Route path='/pos' component={Pos} />
					<Route path="/pos-invoice" component={PosInvoice} />
					<Route path={`${localStorage.getItem('posId') ? '/saleorder/:posId' : '/saleorder'}`} component={SaleOrder} />
					<Route path="/pos-listing" component={PosListing} />
					<Route path="/cash-book" component={Cashbook} />
					<Route path="/thermal-invoice" component={ThermalInvoice} />
					<Route path="/cash-receive" component={CashReceive} />
					<Route path="/expense" component={Expence} />
					<Route path="/checkout" component={Checkout} />
					<Route path="/splash" component={Splash} />
					<Route path="/return-invoice/:invoiceId" component={ReturnInvoice} />
				</HashRouter>

			</div>
		);
	}
}
export default App;
