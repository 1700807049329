import React, { Component } from "react";
import $ from "jquery";
import logo from "../assets/images/logo-eloerp.png";
import cart from "../assets/images/cart.svg";
import fish from "../assets/images/fish.svg";
import grocery from "../assets/images/grocery.svg";
import Mushrooms from "../assets/images/Mushrooms.svg";
import fruits from "../assets/images/fruit.svg";
import dried from "../assets/images/dried.svg";
import icon8 from "../assets/images/icons8-delete-50.png";
import tomato from "../assets/images/tomato.png";
import iconBullet from "../assets/images/icons8-bulleted-list-50.png";
import Header from "./Header.js";
import Footer from "./ProductFooter.js";
import { NavLink } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { api_url } from "../Config.js";
import { PostData } from "./PostData.js";
import loaderImage from "../assets/images/fake-loder.gif";
import homebtn from "../assets/images/homebtn.png";
import bottom from "../assets/images/bottom.png";
import close_popup from "../assets/images/close_popup.png";
import editform from "../assets/images/edit_form.png";
import logout from "../assets/images/icons8-logout-rounded-down-96.png";
import swal from "sweetalert";
import refresh from "../assets/images/icons8-refresh-24.png";
class TimeSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      backupProducts: [],
      categories: [],
      warehouse_id: localStorage.getItem('warehouse_id') ? localStorage.getItem('warehouse_id') : 1,
      redirectToReferrer: false,
      user_id: localStorage.getItem("userData"),
      base_url: api_url.base_url,
      img_url: api_url.img_url,
      loading: false,
      posProduct:
        JSON.parse(localStorage.getItem("posProduct")) != null
          ? JSON.parse(localStorage.getItem("posProduct"))
          : [],
      total_amount: localStorage.getItem("total_amount")
        ? localStorage.getItem("total_amount")
        : 0,

      // posProduct:[],
      // total_amount:0,
      limit: 10,
      start: 0,
      dvalue: 0,
      redirect: false,
      prefix: "",
      suffix: "",
      btnLoading: false,
      editData: [],
      decimalValue: null,
      user_id: JSON.parse(sessionStorage.getItem("userData"))
        ? JSON.parse(sessionStorage.getItem("userData"))
        : JSON.parse(localStorage.getItem("userData")),
    };
    this.logout = this.logout.bind(this);
    this.selectedCategory = this.selectedCategory.bind(this);
    this.showPopup = this.showPopup.bind(this);
    this.changeEditQty = this.changeEditQty.bind(this);
    this.increaseQty = this.increaseQty.bind(this);
    this.decreaseQty = this.decreaseQty.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.loadMore = this.loadMore.bind(this);
  }


  componentDidUpdate() {




    localStorage.setItem("user_id", this.state.user_id);

    $(".product_btn img").click(function () {
      $(".sidebar_left").fadeIn();
      $(".overlay_body_box").fadeIn();
    });

    $(".close_btn_product img,.overlay_body_box").click(function () {
      $(".sidebar_left").fadeOut();
      $(".overlay_body_box").fadeOut();
    });




    $(".close_popup,.white_overlay,.done_btn").click(function () {
      $(".btm_slide_popup").fadeOut("500");
      $(".white_overlay").fadeOut("500");
    });

  }

  componentDidMount() {

    $('#recButton').addClass("notRec");

    $('#recButton').click(function () {
      if ($('#recButton').hasClass('notRec')) {
        $('#recButton').removeClass("notRec");
        $('#recButton').addClass("Rec");
      }
      else {
        $('#recButton').removeClass("Rec");
        $('#recButton').addClass("notRec");
      }
    });


    const prefixurl = this.state.base_url + "sale_api/GetPrefix/" + this.state.user_id;
    fetch(prefixurl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            prefix: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
    const decimalsUrl = this.state.base_url + "sale_api/getDecimals/" + this.state.user_id;
    fetch(decimalsUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            decimalValue: parseFloat(result),
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
    const sufixurl = this.state.base_url + "sale_api/GetSuffix/" + this.state.user_id;
    fetch(sufixurl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            suffix: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
    this.setState({ btnLoading: true });

    $(".cata-sub-slider").on("scroll", function () {
      var val = $(this).scrollLeft();
      if (
        $(this).scrollLeft() + $(this).innerWidth() >=
        $(this)[0].scrollWidth
      ) {
        $(".cata-sub-slider .nav-next-").hide();
      } else {
        $(".cata-sub-slider .nav-next-").show();
      }
      if (val == 0) {
        $(".cata-sub-slider .nav-prev-").hide();
      } else {
        $(".cata-sub-slider .nav-prev-").show();
      }
    });
    $(".cata-sub-slider .nav-next-").on("click", function () {
      $(".cata-sub-slider").animate({ scrollLeft: "+=460" }, 500);
    });
    $(".cata-sub-slider .nav-prev-").on("click", function () {
      $(".cata-sub-slider").animate({ scrollLeft: "-=460" }, 500);
    });

    $(".cata-sub-nav").on("scroll", function () {
      var val = $(this).scrollLeft();

      if (
        $(this).scrollLeft() + $(this).innerWidth() >=
        $(this)[0].scrollWidth
      ) {
        $(".nav-next").hide();
      } else {
        $(".nav-next").show();
      }

      if (val == 0) {
        $(".nav-prev").hide();
      } else {
        $(".nav-prev").show();
      }
    });
    $(".nav-next").on("click", function () {
      $(".cata-sub-nav").animate({ scrollLeft: "+=43.2" }, 200);
    });
    $(".nav-prev").on("click", function () {
      $(".cata-sub-nav").animate({ scrollLeft: "-=43.2" }, 200);
    });

    this.setState({ loading: true });
    const getUrl = this.state.base_url + "sale_api/getPOSProducts?warehouse_id=" + this.state.warehouse_id + "&limit=" + this.state.limit + "&start=" + this.state.start + "&user_id=" + this.state.user_id;
    fetch(getUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            products: result.products,
            backupProducts: result.products,
            loading: false,
            btnLoading: false,
          });
        },
        (error) => {
          this.setState({ loading: false });
        }
      );
    const getCategoriesNames = this.state.base_url + 'sale_api/getCategories/' + this.state.user_id;
    fetch(getCategoriesNames,
      {
        method: 'POST',
        headers:
        {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: this.state.user_id })
      })
      .then((response) => response.json()
        .then((result) => {
          this.setState({
            categories: result
          })
        }))
      .catch((error) => {
        this.setState({ loading: false })
      });

    if (
      localStorage.getItem("posId") !== null
    ) {
      const getIdUrl =
        this.state.base_url +
        "sale_api/getInvoice/" +
        this.state.user_id + "/" +
        localStorage.getItem("posId");
      fetch(getIdUrl)
        .then((res) => res.json())
        .then(
          (result) => {
            localStorage.setItem("customer_id", result.model.data.customerId);
            localStorage.removeItem('posProduct');
            if (result.items) {
              this.setState(
                {
                  posProduct: [],
                  editData: result.items,
                  // total_amount:(parseFloat(result.basic_details.total)).toFixed(this.state.decimalValue) ,
                  loading: false,
                },
                function () {
                  // console.log(result.basic_details.total)

                  this.state.editData.map((product, index) => {
                    const uname = product.product_id;
                    const upname = product.productName;
                    const uuom = product.UOMName;
                    const uomId = product.UOMId;
                    const uqty = product.qty;
                    const nunit_price = parseFloat(product.amount).toFixed(this.state.decimalValue);
                    const totalprice = nunit_price * uqty;

                    let discountValue = 0;
                    let printValue = 0;
                    let ActualValue = 0;
                    const discount_type = localStorage.getItem('dis_type');
                    const discount_amount = localStorage.getItem('discount');
                    if (discount_type === '1') {
                      discountValue = discount_amount / 100;
                      printValue = totalprice * discountValue;
                      ActualValue = totalprice - printValue;
                      this.state.total_amount += ActualValue;
                    }
                    else {
                      discountValue = discount_amount;
                      ActualValue = totalprice - (discountValue * uqty);
                      this.state.total_amount += ActualValue;
                    }
                    const verynewproduct = {
                      name: uname,
                      qty: uqty,
                      unit_price: nunit_price,
                      pname: upname,
                      uom: uomId,
                      uomName: uuom,
                      is_stockable: 1
                    };

                    this.state.posProduct.push(verynewproduct);
                  });

                  localStorage.setItem(
                    "posProduct",
                    JSON.stringify(this.state.posProduct)
                  );
                  // console.log(this.state.posProduct)
                  // localStorage.setItem(
                  //   "total_amount",
                  //   JSON.stringify(this.state.total_amount)
                  // );
                }
              );
            }
          },
          (error) => { }
        );
    }



    $(".request_door_click").on("click", function () { });
    $(".request_door_click").click(function () {
      $(".process_complete").hide();
    });
    $(".request_door_click").click(function () {
      $(".last_step").show();
    });
  }

  componentWillMount() {
    if (sessionStorage.getItem("userData")) {
      this.getUserData();
    } else if (localStorage.getItem("userData")) {
      this.getUserData();
    } else {
      this.setState({ redirectToReferrer: true });
    }
  }
  getUserData() {
    JSON.parse(sessionStorage.getItem("userData"));
    JSON.parse(localStorage.getItem("userData"));
  }
  componentWillUnmount() {
    localStorage.removeItem('total_amount');
    //  


  }
  searchItem(e) {
    this.setState({ btnLoading: true });
    var search = e.target.value;

    if (search == null) {
      this.setState({
        products: this.state.backupProducts,
      });
    } else {
      this.setState({ loading: true });
      const searchurl =
        this.state.base_url + "sale_api/getSearchResult?search=" + search + "&warehouse_id=" + this.state.warehouse_id + "&limit=" + this.state.limit + "&start=" + this.state.offest + "&user_id=" + this.state.user_id;
      fetch(searchurl)
        .then((res) => res.json())
        .then(
          (result) => {
            if (result == null) {
              this.setState({
                products: this.state.backupProducts,
                loading: false,
                btnLoading: false,
              });
            } else {
              this.setState({
                products: result,
                loading: false,
                btnLoading: false,
              });
            }
          },
          (error) => {
            this.setState({ loading: false, btnLoading: false });
          }
        );
    }
  }

  selectedCategory(e) {
    var selector = ".tabs li a";
    $(selector).removeClass("current");
    $('.traget_class_' + e.target.name).addClass("current");
    // $(".sidebar_left").fadeOut();
    // $(".overlay_body_box").fadeOut();
    // document.getElementById("close_btn_product").click();
    // window.location.reload(false);
    var select = e.target.name;

    if (select == null || select == 0) {
      this.setState({
        products: this.state.backupProducts,
      });
    } else {
      this.setState({ loading: true, btnLoading: true });
      const selecturl =
        this.state.base_url + "sale_api/getselectCategory?select=" + select + "&warehouse_id=" + this.state.warehouse_id;
      fetch(selecturl)
        .then((res) => res.json())
        .then(
          (result) => {
            if (result == null) {
              this.setState({
                products: this.state.backupProducts,
                loading: false,
                btnLoading: false,
              });
            } else {
              this.setState({
                products: result,
                loading: false,
                btnLoading: false,
              });
            }
          },
          (error) => {
            this.setState({ loading: false, btnLoading: false });
          }
        );
    }
  }

  async increaseQty(id, uomId, UOMName, unit_price, name, is_stockable, remainingStock) {
    let discountValue = 0;
    const discount_type = localStorage.getItem('dis_type');
    const discount_amount = localStorage.getItem('discount');
    if (discount_type === '1') {
      discountValue = discount_amount / 100;
    }
    else {
      discountValue = discount_amount;
    }



    var product_name = name;
    var uomId = uomId ? uomId : "";
    var uomName = UOMName ? UOMName : "";
    var unit_price = unit_price;
    var targetnaem = id;
    var is_stockable = is_stockable ? is_stockable : 0;

    var product = {
      name: targetnaem,
      qty: 1,
      unit_price: unit_price,
      pname: product_name,
      uom: uomId,
      uomName: uomName,
      is_stockable: is_stockable
    };

    // await this.state.posProduct.push(product)
    for (var i = 0; i < this.state.posProduct.length; i++) {
      var sname = this.state.posProduct[i].name;

      var slectproduct = this.state.posProduct[i];
      const posProduct = [...this.state.posProduct];
      if (sname == targetnaem) {
        var svalue = slectproduct.qty;

        const updateditems = posProduct.filter(
          (item) => item.name !== slectproduct.name
        );

        if (parseFloat(svalue) < parseFloat(remainingStock)) {
          this.setState({ posProduct: updateditems }, function () {
            var nproduct = {
              name: targetnaem,
              qty: parseFloat(svalue) + parseFloat(1),
              unit_price: unit_price,
              pname: product_name,
              uom: uomId,
              uomName: uomName,
              is_stockable: is_stockable
            };
            this.state.posProduct.push(nproduct);

            localStorage.setItem(
              "posProduct",
              JSON.stringify(this.state.posProduct)
            );
          });
        } else {
          swal('Not enough stock');
          return false;
        }
      }
    }

    await this.state.posProduct.push(product);
    let increaseAmount = 0;
    if (discount_type === '1') {
      increaseAmount = discountValue * unit_price;
    }
    else {
      increaseAmount = discountValue;
    }
    await this.setState((old) => {
      return {
        total_amount:
          parseFloat(old.total_amount) +
          parseFloat(
            unit_price * 1 -
            (increaseAmount)
          ),
      };
    });
    await localStorage.setItem(
      "posProduct",
      JSON.stringify(this.state.posProduct)
    );
    localStorage.setItem(
      "total_amount",
      parseFloat(this.state.total_amount).toFixed(this.state.decimalValue)
    );
  }

  async decreaseQty(id, uomId, UOMName, unit_price, name, is_stockable) {
    let discountValue = 0;
    const discount_type = localStorage.getItem('dis_type');
    const discount_amount = localStorage.getItem('discount');
    if (discount_type === '1') {
      discountValue = discount_amount / 100;
    }
    else {
      discountValue = discount_amount;
    }


    var product_name = name;
    var uomId = uomId ? uomId : "";
    var uomName = UOMName ? UOMName : "";
    var unit_price = unit_price;
    var targetnaem = id;
    var is_stockable = is_stockable ? is_stockable : 0;

    this.state.posProduct.map((c, i) => {
      if (c.name == targetnaem) {
        if (c.qty > 1) {
          const olvalue = c.qty;
          const olproduct = [...this.state.posProduct];
          const delit = olproduct.filter((item) => item.name !== targetnaem);
          this.setState(
            {
              posProduct: delit,
            },
            function () {
              this.state.posProduct.push({
                name: targetnaem,
                qty: olvalue - parseFloat(1),
                unit_price: unit_price,
                pname: product_name,
                uom: uomId,
                uomName: uomName,
                is_stockable: is_stockable
              });
              let decreaseAmount = 0;
              if (discount_type === '1') {
                decreaseAmount = discountValue * unit_price;
              }
              else {
                decreaseAmount = discountValue;
              }
              this.setState((old) => {
                return {
                  total_amount:
                    parseFloat(old.total_amount) -
                    parseFloat(
                      unit_price * 1 -
                      (decreaseAmount)
                    ),
                };
              });
              localStorage.setItem(
                "posProduct",
                JSON.stringify(this.state.posProduct)
              );
            }
          );
        } else {
          const oproduct = [...this.state.posProduct];
          const dit = oproduct.filter((item) => item.name !== targetnaem);
          this.setState(
            {
              posProduct: dit,
            },
            function () {
              let decAmount = 0;
              if (discount_type === '1') {
                decAmount = discountValue * unit_price;
              }
              else {
                decAmount = discountValue;
              }
              this.setState((old) => {
                return {
                  total_amount:
                    parseFloat(old.total_amount) -
                    parseFloat(
                      unit_price * 1 -
                      (decAmount)
                    ),
                };
              });
              localStorage.setItem(
                "posProduct",
                JSON.stringify(this.state.posProduct)
              );
              localStorage.setItem(
                "total_amount",
                parseFloat(this.state.total_amount).toFixed(this.state.decimalValue)
              );
            }
          );
        }
      }
    });
  }

  async addItemToCart(id, UOMId, UOMName, unit_price, name, is_stockable, remainingStockd) {
    let quantity_to_add = $('#edit_product_' + id).val();
    let remainingStock = parseFloat(remainingStockd);
    let qty = parseFloat(quantity_to_add);
    if (remainingStock < qty) {
      swal('Not enough stock');
      $("#edit_unit_total_" + id).html(unit_price);
      $("#edit_product_" + id).val('');
      return false;
    }
    if (isNaN(qty)) {
      swal('Please select quantity');
      $("#edit_unit_total_" + id).html(unit_price);
      $("#edit_product_" + id).val('');
      return false;
    }
    if (qty <= 0) {
      swal('Please select quantity');
      return false;
    }

    let discountValue = 0;
    const discount_type = localStorage.getItem('dis_type');
    const discount_amount = localStorage.getItem('discount');
    if (discount_type === '1') {
      discountValue = discount_amount / 100;
    }
    else {
      discountValue = discount_amount;
    }

    var product_name = name;
    var uomId = UOMId;
    var uomName = UOMName ? UOMName : "";
    var unit_price = unit_price;
    var targetnaem = id;
    var is_stockable = is_stockable;

    var product = {
      name: id,
      uomName: uomName,
      qty: qty,
      unit_price: unit_price,
      pname: product_name,
      uom: uomId,
      is_stockable: is_stockable
    };

    // await this.state.posProduct.push(product)
    for (var i = 0; i < this.state.posProduct.length; i++) {
      var sname = this.state.posProduct[i].name;

      var slectproduct = this.state.posProduct[i];
      const posProduct = [...this.state.posProduct];
      if (sname == targetnaem) {
        var svalue = slectproduct.qty;

        const updateditems = posProduct.filter(
          (item) => item.name !== slectproduct.name
        );

        this.setState({ posProduct: updateditems }, function () {
          var nproduct = {
            name: id,
            uomName: uomName,
            qty: parseFloat(qty),
            unit_price: unit_price,
            pname: product_name,
            uom: uomId,
            is_stockable: is_stockable
          };
          this.state.posProduct.push(nproduct);

          localStorage.setItem(
            "posProduct",
            JSON.stringify(this.state.posProduct)
          );
        });
      }
      $('#edit_product_' + id).val('');
    }

    await this.state.posProduct.push(product);
    let increaseAmount = 0;
    if (discount_type === '1') {
      increaseAmount = discountValue * unit_price;
    }
    else {
      increaseAmount = discountValue;
    }
    await this.setState((old) => {
      return {
        total_amount:
          parseFloat(old.total_amount) +
          parseFloat(
            unit_price * qty -
            (increaseAmount)
          ),
      };
    });
    await localStorage.setItem(
      "posProduct",
      JSON.stringify(this.state.posProduct)
    );
    localStorage.setItem(
      "total_amount",
      parseFloat(this.state.total_amount).toFixed(this.state.decimalValue)
    );
    $("#edit_unit_total_" + id).html('');
    $("#edit_product_" + id).val('');
    // update cart function ends here.......

  }
  saveProducts() {
    if (localStorage.getItem('posId') && localStorage.getItem('posId') > 0) {
      localStorage.setItem('is_edit', '1');
    }
    this.setState({ redirect: true });
  }

  showPopup(value) {


    $(".btm_slide_popup_" + value).css("bottom", "0");
    $(".btm_slide_popup_" + value).animate({
      height: 'toggle'
    });
    $(".white_overlay").fadeIn("500");
    document.getElementById("edit_product_" + value).focus();
  }
  changeEditQty(e) {
    var unit_price = $("#edit_unit_total_" + e.target.size).attr('data-unit');
    var qty = e.target.value;
    if (isNaN(qty)) {
      $("#edit_unit_total_" + e.target.size).html('');
    } else {


      var upVal = parseFloat(unit_price) * parseFloat(qty);
      $("#edit_unit_total_" + e.target.size).html(this.state.prefix + parseFloat(upVal).toFixed(this.state.decimalValue) + this.state.suffix);
      if (e.target.value == "") {
        $("#edit_unit_total_" + e.target.size).html('');
      }
    }



  }
  clearSearch() {
    $("#search_here_something").val('');
    this.setState({ products: this.state.backupProducts })
  }
  loadMore() {
    this.setState({ btnLoading: true })
    const start = parseFloat(this.state.start) + parseFloat(20);
    const loadUrl = this.state.base_url + "sale_api/getPOSProducts?warehouse_id=" + this.state.warehouse_id + "&limit=" + this.state.limit + "&start=" + start + "&user_id=" + this.state.user_id;
    fetch(loadUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.products.length > 0) {
            this.setState({
              products: result.products,
              backupProducts: result.products,
              btnLoading: false,
            });
          }
        },
        (error) => {
          this.setState({ btnLoading: false });
        }
      );

  }

  remove_this_item(id) {
    const posProduct = this.state.posProduct.filter(item => item.name !== id)
    this.setState({ posProduct: posProduct });
    localStorage.setItem("posProduct", JSON.stringify(posProduct));
  }
  empty_whole_cart() {
    const posProduct = [];
    this.setState({ posProduct: posProduct });
    localStorage.setItem("posProduct", JSON.stringify(posProduct));
  }
  logout() {
    sessionStorage.setItem("userData", '');
    localStorage.setItem("userData", '');

    this.setState({ redirectToReferrer: true }, function () {
    });
    this.setState({ showLogin: 'yes' }, function () {
    })

  }
  render() {

    const { posProduct, prefix, suffix, decimalValue } = this.state;
    let discountValue = 0;
    const discount_type = localStorage.getItem('dis_type') ? localStorage.getItem('dis_type') : 0;
    const discount_amount = localStorage.getItem('discount');
    if (discount_type === '1') {
      discountValue = discount_amount / 100;
    }
    else {
      discountValue = discount_amount;
    }

    let sign = '';
    if (discount_type === '1') {
      sign = '%';
    }
    else {
      sign = this.state.suffix;
    }

    const { editData } = this.state;
    const { btnLoading } = this.state;
    let dvalue = 0;
    const cproducts = this.state.posProduct;
    let selectedId = "";
    function fProducts(cproducts, id) {
      let selectedData = cproducts.filter((item) => item.name === id);
      var qty = 0;
      if (selectedData[0]) {
        qty = selectedData[0].qty;
      }

      return qty;
    }

    function fRupees(cproducts, id) {

      let selectedData = cproducts.filter((item) => item.name === id);
      var priceValues = 0;
      if (selectedData[0]) {
        const totalAmount = selectedData[0].qty * selectedData[0].unit_price;

        let show_value = 0;
        if (discount_type === '1') {
          show_value = (totalAmount * discountValue);
          priceValues = parseFloat(totalAmount) - parseFloat(show_value);
        }
        else {
          show_value = ((totalAmount) - (discountValue * selectedData[0].qty));
          priceValues = (show_value);
        }

      }

      return prefix + priceValues.toFixed(decimalValue) + suffix;
    }


    function grandTotalAmount() {
      var ActualValue = 0;
      var printValue = 0;
      var totalprice = 0;
      var discountValue = 0;
      var totalQty = 0;
      posProduct.map(value => {
        var qty = value.qty
        var unit_price = value.unit_price;
        totalprice += parseFloat(unit_price * qty);
        totalQty += value.qty;
      });

      const discount_type = localStorage.getItem('dis_type');
      const discount_amount = localStorage.getItem('discount');
      if (discount_type === '1') {
        discountValue = (totalprice * discount_amount / 100);
        ActualValue = totalprice - discountValue;
      }
      else {
        discountValue = discount_amount;
        ActualValue = totalprice - (discountValue * totalQty);
      }


      return ActualValue;
    }

    const deci = this.state.decimalValue;

    function numberWithCommas(x) {
      return Number(parseFloat(x).toFixed(deci)).toLocaleString('en', {
        minimumFractionDigits: deci
      });
    }

    const productDetails = this.state.products.map((p, ind) => {
      if (p.remainingStock > 0) {
        return (
          <li key={ind} >

            <div className="product_box">
              <a id={`popup_cart_${p.id}`} className={`popup_cart popup_cart_${p.id}`} onClick={() => this.showPopup(p.id)}>
                <h4>
                  {p.productName +
                    "" +
                    (p.UOMName ? " - (" + p.UOMName + ")" : "") +
                    ""}
                </h4>
                <b>
                  {fRupees(cproducts, p.id)}
                </b>


                <div className="shdown_box">
                  <img className="product_label" src={this.state.img_url + `/${p.image_name}`} />
                </div>
              </a>

              <div className="quantity_box_pos">
                <input
                  type="button"
                  name={p.id}
                  id={p.unit_price}
                  title={p.productName}
                  defaultValue="-"
                  placeholder={p.UOMId}
                  stock={p.is_stockable}
                  className="qty-minus_pos minus_plus_pos"
                  onClick={() => this.decreaseQty(p.id, p.UOMId, p.UOMName, p.unit_price, p.productName, p.is_stockable)}
                />
                <input type="number" className="value_box_pos" value={fProducts(cproducts, p.id)} />
                <input
                  type="button"
                  name={p.id}
                  placeholder={p.UOMId}
                  id={p.unit_price}
                  title={p.productName}
                  stock={p.is_stockable}
                  defaultValue="+"
                  className="qty-plus_pos minus_plus_pos"
                  onClick={() => this.increaseQty(p.id, p.UOMId, p.UOMName, p.unit_price, p.productName, p.is_stockable, p.remainingStock)}
                />
                <span className="refresh_img_container">
                  <img className="item_refresh" onClick={() => this.remove_this_item(p.id)} src={refresh} alt="" />
                </span>

              </div>
            </div>

          </li>
        );
      }
    });


    const popupProducts = this.state.products.map((p, ind) => {
      let defaultQty = 0;
      this.state.posProduct.map(value => {
        if (p.id == value.name) {
          defaultQty = value.qty;
        }
        //console.log(this.state.posProduct)
      })
      return (
        <div key={ind} className={`btm_slide_popup btm_slide_popup_${p.id}`}>
          <div className="close_popup">
            <img src={close_popup} alt="" />
          </div>
          <div className="inner_qty_box">
            <input type="number" autoFocus="true" defaultValue="" inputMode="decimal" size={p.id} placeholder="Enter Qty" onChange={this.changeEditQty} className="edit_product" id={`edit_product_${p.id}`} />
            <h3>
              {p.productName +
                "" +
                (p.UOMName ? " - (" + p.UOMName + ")" : "") +
                ""}
            </h3>
            <b data-unit={p.unit_price} id={`edit_unit_total_${p.id}`}>
              {this.state.prefix + parseFloat(p.unit_price).toFixed(this.state.decimalValue) + this.state.suffix}
            </b>

            <a className="done_btn" onClick={() => this.addItemToCart(p.id, p.UOMId, p.UOMName, p.unit_price, p.productName, p.is_stockable, p.remainingStock)}>Add to cart</a>

          </div>
        </div>
      );
    });

    if (this.state.redirectToReferrer) {
      return <Redirect to={`/`} />;
    }
    // if (localStorage.getItem('order_no')==null) {
    // return (<Redirect to={`/item-selection`}/>)
    // }

    if (this.state.redirect) {
      if (localStorage.getItem('customer_id') == 1) {
        return <Redirect to={`/pos-invoice`} />;
        localStorage.removeItem('payment_amount');
      } else {
        return <Redirect to={`/checkout`} />;
      }
    }

    return (
      <React.Fragment>
        <div className="dashboard_body" id="pos_dashb">
          <div className="pos_main">
            <div className="pos_black_box">
              <div className="row">
                <div className="product_btn">
                  <img src={iconBullet} alt="" />
                </div>
                <div className="col-sm-2 logo_box">
                  <NavLink to="/dashboard"><img src={logo} alt="" /></NavLink>
                  <div className="edit_form_data">

                    <NavLink to={`${localStorage.getItem('posId') != null ? '/saleorder/' + localStorage.getItem('posId') : '/saleorder'}`} ><img src={editform} alt="" /></NavLink>
                  </div>
                </div>
                <div className="col-sm-10 search_box">
                  <svg viewBox="0 0 24 24"><path d="M9.5 4a6.5 6.5 0 0 1 4.932 10.734l5.644 5.644l-.707.707l-5.645-5.645A6.5 6.5 0 1 1 9.5 4zm0 1a5.5 5.5 0 1 0 0 11a5.5 5.5 0 0 0 0-11z" fill="#626262" /></svg>
                  <input
                    type="text"
                    id="search_here_something"
                    className="form-control"
                    placeholder="Search Here"
                    onChange={this.searchItem.bind(this)}
                  />
                  {
                    // <button type="button">SEARCH</button>
                  }
                  <div className="close_search"><img onClick={this.clearSearch} src={close_popup} alt="" /></div>
                  <div className="logout_btn">
                    <a href="#" onClick={this.logout}><img src={logout} alt="" /></a>
                    <button id="recbtn" class="Rec">Recording</button>
                  </div>

                </div>
              </div>
              <div className="row">
                <div className="col-sm-2 sidebar_left">
                  <div className="close_btn_product">
                    <img id="close_btn_product" src={icon8} alt="" />
                  </div>
                  <ul className="tabs">
                    <li>
                      <a
                        className={`tab-link current traget_class_${0}`} name="0" onClick={this.selectedCategory} data-tab="tab-1"><img src={cart} alt="" />

                        All Products

                      </a>

                    </li>
                    {this.state.categories != null ? (
                      this.state.categories.map((c, i) => (
                        <li key={i} >
                          <a
                            name={c.id}
                            className={`tab-link traget_class_${c.id}`}
                            onClick={this.selectedCategory}><img src={c.icon_image} alt="" /> {c.groupName}
                          </a>
                        </li>
                      ))
                    ) : (
                      <li>
                        <a
                          className={`tab-link current traget_class_${0}`} name="0" onClick={this.selectedCategory} data-tab="tab-1"><img src={cart} alt="" />

                          All Products

                        </a>

                      </li>
                    )}
                    {
                      // <li className="tab-link" data-tab="tab-3"><img src={grocery} alt=""/> Grocery</li>
                      //  <li className="tab-link" data-tab="tab-4"><img src={Mushrooms} alt=""/> Mushrooms</li>
                      //  <li className="tab-link" data-tab="tab-5"><img src={fruits} alt=""/> Fruits</li>
                      //  <li className="tab-link" data-tab="tab-6"><img src={dried} alt=""/> Dried fruits & nuts</li>
                    }

                  </ul>
                </div>
                <div className="col-sm-10 contents_right">
                  <div id="tab-1" className="tab-content current">
                    <div className="products_listing">
                      <ul>
                        {this.state.loading ? (
                          <div style={{ textAlign: 'center', padding: '73px 0' }}><img src={loaderImage} alt='Loading' /></div>
                        ) : this.state.products != null && this.state.products != "" ? (
                          productDetails
                        ) : (
                          <div className="no_records">No Record Found</div>
                        )}

                        {
                          //<li><button onClick={this.loadMore}>Load More</button></li>
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottom-nav" >
                <div className="fab"><NavLink to="/dashboard">  <img src={homebtn} alt="" /></NavLink></div>

                <div className="total_btm-bar" id="pos_total">
                  <div className="total_amount_box">
                    <b className="total_hide">Total:</b> <span id="grand_total">{this.state.prefix + numberWithCommas(grandTotalAmount()) + this.state.suffix}</span>

                    <img className="img_tooltip_cart" onClick={() => this.empty_whole_cart()} src={refresh} />
                    <button
                      className="button-loadings"
                      type="button"
                      onClick={this.saveProducts.bind(this)}
                      disabled={btnLoading}
                    >
                      {btnLoading && (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                      )}
                      {btnLoading && <span></span>}
                      {!btnLoading && (
                        <span>
                          Next <i className="fa fa-long-arrow-right"></i>
                        </span>
                      )}
                    </button>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>

        <div className="orange_fixed">
          <img src={bottom} alt="" />
        </div>
        <div className="overlay_body_box"></div>

        <div className="white_overlay"></div>

        {
          popupProducts
        }


      </React.Fragment>
    );
  }
}
export default TimeSelection;


