import React, { Component } from "react";
import $ from "jquery";
import Header from "./Header.js";
import { Redirect } from "react-router-dom";
import { api_url } from "../Config.js";
import DatePicker from "react-datepicker";
import save from "../assets/images/save_icon.png";
import print from "../assets/images/print-icon.png";
import "react-datepicker/dist/react-datepicker.css";
import loaderImage from "../assets/images/770.gif";
import {swal} from 'sweetalert';
class Expenses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            base_url: api_url.base_url,
            date: null,
            account_ledger: null,
            amount: null,
            payment_method: null,
            loading: false,
            amount_error: null,
            account_error: null,
            user_id: JSON.parse(sessionStorage.getItem("userData"))
                ? JSON.parse(sessionStorage.getItem("userData"))
                : JSON.parse(localStorage.getItem("userData")),
            allLedgers: [],
            cash_bank: []
        };
        // window.location.reload(true)
        this.onChange = this.onChange.bind(this);
        this.receiveCash = this.receiveCash.bind(this);

    }

    componentDidMount() {
        this.setState({ screenLoading: true })
        const getCustUrl = this.state.base_url + "sale_api/getAllLedgers/" + this.state.user_id;
        fetch(getCustUrl)
            .then((res) => res.json())
            .then(
                (result) => {
                    if (result.ledgers) {
                        this.setState({
                            allLedgers: result.ledgers,
                            cash_bank: result.cash_bank,
                            payment_method: result.cash_bank[0].id,
                            screenLoading: false
                        });
                    }
                },
                (error) => {
                    this.setState({ screenLoading: false });
                }
            );
    }

    componentWillMount() {
        if (sessionStorage.getItem("userData")) {
            this.getUserData();
        } else if (localStorage.getItem("userData")) {
            this.getUserData();
        } else {
            this.setState({ redirectToReferrer: true });
        }
    }
    getUserData() {
        JSON.parse(sessionStorage.getItem("userData"));
        JSON.parse(localStorage.getItem("userData"));
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onChanging(date) {
        this.setState({ date });
    }
    handleChange = (date) => {
        var d = date.getDate();
        var m = date.getMonth();
        var y = date.getFullYear();
        var month = m + parseFloat(1);
        var formatedDate = y + '-' + month + '-' + d;
        this.setState({ date: formatedDate })
    }

    validation() {
        if (this.state.amount === "") {
            this.setState({
                amount_error: "* Please enter amount",
                account_error: "",
            });
        } else if (this.state.account_ledger === "") {
            this.setState({
                amount_error: "",
                account_error: "* Please select ledger",
            });
        }else if (this.state.payment_method === "") {
            this.setState({
                amount_error: "",
                account_error: "* Please Select Receiving Method",
            });
        } else {
            return true;
        }
    }

    receiveCash() {
        var date = $("#date").val();
        var cash = $("#cash").val();
        var account = $("#account").val();
        var amount = $("#amount").val();
        this.setState(
            { date: date, payment_method: cash, account_ledger: account, amount: amount },
            function () {
                if (this.validation() === true) {
                    this.setState({ loading: true, account_error: '', amount_error: '',screenLoading:true });
                    let sendData = {
                        date: date,
                        payment_method: cash,
                        account_ledger: account,
                        amount: amount,
                        user_id: this.state.user_id
                    }
                    const cashReceApi = this.state.base_url + 'sale_api/saveExpenses';
                    fetch(cashReceApi,
                        {
                            method: 'POST',
                            headers:
                            {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(sendData)
                        })
                        .then((response) => response.json()
                            .then((result) => {
                                if(result.response===0){
                                    swal(result.message);
                                    this.setState({
                                        account_ledger: "",
                                        amount: 0,
                                        loading: false,
                                        screenLoading: false
                                    });
                                }
                                if(result.response===1){
                                    swal(result.message);
                                    this.setState({
                                        account_ledger: "",
                                        amount: 0,
                                        loading: false,
                                        screenLoading: false
                                    }); 
                                }
                            })
                        )
                        .catch((error) => {
                            console.log(error);
                            this.setState({ loading: false,screenLoading: false, error_message: error });
                        });
                }
            }
        );

    }

    render() {
        // window.location.reload(true)
        const loading = this.state.loading
        const date = new Date();
        var d = date.getDate();
        var m = date.getMonth();
        var y = date.getFullYear();
        var month = m + parseFloat(1);
        var formatedDate = y + '-' + month + '-' + d;
        const setDate = (this.state.date !== null) ? this.state.date : formatedDate;
        const ndate = setDate;
        if (this.state.redirectToReferrer) {
            return <Redirect to='/login' />;
        }

        return (
            <div>
                {
                    !this.state.screenLoading &&

                    <Header />
                }

                <div className="dashboard_body main_body_style auto_scrol" id="sale_order_page">
                    <div className="pos_main">
                        <div className="pos_black_box" id="new_selection">
                            <div className="main_body">
                                <div className="login_screen" id="uder_eng_language">
                                    {
                                        this.state.screenLoading ?
                                            <div className="loader_middle" style={{ padding: '190px 0' }}><img src={loaderImage} alt='Loading' /></div>
                                            :
                                            <div className="user_login Select_language process_complete">
                                                <div className="form_box_delivery">
                                                    <h3>Expenses</h3>
                                                    <div className="row cashreceive">
                                                        <div className="col-sm-12 delivery_address">
                                                            <label style={{ zIndex: 1 }}>Date</label>
                                                            <DatePicker
                                                                value={ndate}
                                                                name="date"
                                                                id="date"
                                                                onChange={(e) => this.handleChange(e)}
                                                            />
                                                        </div>
                                                        <div
                                                            className="col-sm-12 delivery_address"
                                                            style={{ display: "block" }}
                                                        >
                                                            <label>Cash/Bank</label>
                                                            <select id="cash" onChange={this.onChange} name="payment_method">
                                                                {
                                                                    this.state.cash_bank.length > 0 &&
                                                                    this.state.cash_bank.map((bank, ind) => {
                                                                        if (bank.id > 0) {
                                                                            return <option value={bank.id} key={ind}>{bank.ledgerName}</option>
                                                                        }

                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        <div
                                                            className="col-sm-12 delivery_address"
                                                            style={{ display: "block" }}
                                                        >
                                                            <label>Account Ledger</label>
                                                            <select id="account" onChange={this.onChange} name="account_ledger">
                                                                <option value=''>Select Ledger</option>
                                                                {
                                                                    this.state.allLedgers.length > 0 &&
                                                                    this.state.allLedgers.map((customer, index) => {


                                                                        if (customer && customer !== null) {
                                                                            return (
                                                                                <option value={customer.id} key={index}>{customer.ledgerName}</option>
                                                                            );
                                                                        }


                                                                    })
                                                                }
                                                            </select>
                                                            <h6 className="voucher_error_message">{this.state.account_error}</h6>
                                                        </div>
                                                        <div
                                                            className="col-sm-12 delivery_address"
                                                            style={{ display: "block" }}
                                                        >
                                                            <label>Amount</label>
                                                            <input type="text" id="amount" onChange={this.onChange} name="amount" placeholder="Enter amount" />
                                                            <h6 className="voucher_error_message">{this.state.amount_error}</h6>
                                                        </div>

                                                    </div>


                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="invoice_listing_ftr" className="total_btm-bar" style={{ padding: "0px" }}>
                    <div className="total-amount">

                        <button
                            className="request_door_click"
                            style={{ fontSize: "18px" }}
                            type="button"
                            onClick={this.receiveCash}
                            disabled={loading}
                        >
                            {loading && (
                                <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px" }}
                                />
                            )}
                            {loading && <span>Loading...</span>}
                            {!loading && <span><img src={save} /> Save</span>}
                        </button>

                        {/* <button
                            className="save-print request_door_click"
                            style={{ fontSize: "18px" }}
                            type="button"
                            onClick={this.receiveCash}
                            disabled={loading}
                        >
                            {loading && (
                                <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px" }}
                                />
                            )}
                            {loading && <span>Loading...</span>}
                            {!loading && <span> <img src={print} /> Save &amp; Print</span>}
                        </button> */}

                        {
                            /*<button onClick={this.receiveCash} class="request_door_click" type="button"><span><img  src={save} /> Save</span></button>
              
              
                            <button onClick={this.receiveCash} class="save-print request_door_click" type="button"><span> <img  src={print} /> Save &amp; Print</span></button>*/

                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default Expenses;
