export const api_url = {
  // base_url: "https://demo.a.eloerp.net/accounting/",
  // com_url: "https://demo.a.eloerp.net/ecommerce/",
  // img_url: "https://demo.a.eloerp.net/",


  // base_url: "https://farmers-shop.a.eloerp.net/accounting/",
  // com_url: "https://farmers-shop.a.eloerp.net/ecommerce/",
  // img_url: "https://farmers-shop.a.eloerp.net/",

  base_url: "https://greenland.food.a.eloerp.net/accounting/",
  com_url: "https://greenland.food.a.eloerp.net/ecommerce/",
  img_url: "https://greenland.food.a.eloerp.net/",
};

